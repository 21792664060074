require('./bootstrap');
require('jquery-zoom');
require('jquery-ui/ui/widgets/autocomplete')
require('vanilla-cookieconsent')

const cc = initCookieConsent()
cc.run({
    current_lang: 'sk',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    level: [
        "necessary"
    ],

    // mode: 'opt-in',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    remove_cookie_tables: true,             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        if(cc.allowedCategory('necessary')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"necessary"
            });
        }


        if(cc.allowedCategory('analytics')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"analytics"
            });

            gtag('consent', 'update', {
                analytics_storage: 'granted',
                ad_storage: 'granted'
            });
        }
    },

    onChange: function (cookie, changed_preferences) {
        if(cc.allowedCategory('analytics')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"analytics"
            });

            gtag('consent', 'update', {
                analytics_storage: 'granted',
                ad_storage: 'granted'
            });
        } else {
            gtag('consent', 'update', {
                analytics_storage: 'denied',
                ad_storage: 'denied'
            });
        }
    },

    languages: {
        'sk': {
            consent_modal: {
                title: 'Na vašom súkromí nám záleží\n',
                description: 'Táto webová stránka používa nevyhnutné súbory cookie na zabezpečenie správneho fungovania a s vaším súhlasom, aj na personalizáciu obsahu našich webových stránok. Podrobnejšie informácie o cookies, ktoré používame, nájdete v časti <button type="button" data-cc="c-settings" class="cc-link">cookies nastavenia</button>',
                primary_btn: {
                    text: 'Rozumiem',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Odmietnúť všetko',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookies nastavenia',
                save_settings_btn: 'Uložiť nastavenia',
                accept_all_btn: 'Prijať všetko',
                reject_all_btn: 'Odmietnúť všetko',
                close_btn_label: 'Zavrieť',
                cookie_table_headers: [
                    {col1: 'Názov'},
                    {col2: 'Doména'},
                    {col3: 'Expirácia'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: '',
                        description: 'Súbory cookie používame na zabezpečenie základných funkcií stránky a na zlepšenie vášho používateľského zážitku. Svoj súhlas pre každú kategóriu môžete kedykoľvek zmeniť.'
                    }, {
                        title: 'Nevyhnutné cookies',
                        description: 'Tieto cookies sú nevyhnutné pre správne fungovanie webovej stránky. Bez týchto súborov cookies nebude webová stránka fungovať správne',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Marketingové cookies',
                        description: 'Vďaka marketingovým cookies bude pre Vás používanie nášho webu pohodlnejšie a prispôsobené Vašim preferenciám. Rovnako uvidíte viac ponuky, ktoré Vás môžu zaujímať a menej nerelevantnej reklamy.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: true,
                            readonly: false
                        }
                    }
                ]
            }
        }
    }
});
if(!cc.validCookie('cc_cookie')) {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: "CookieConsent",
        consentType: "empty"
    });
}

import Splide from '@splidejs/splide';
if($('#card-slider').length) {
    new Splide( '#card-slider', {
        perPage : 5,
        perMove: 1,
        gap: 10,
        pagination: false,
        breakpoints: {
            640: {
                perPage: 2,
            },
        },
        classes: {
            arrows: 'splide__arrows my-arrows',
            arrow : 'splide__arrow my-arrow',
            prev  : 'splide__arrow--prev my-prev',
            next  : 'splide__arrow--next my-next',
        },
    } ).mount();
}

import Cookies from 'js-cookie';
const hostname = window.location.host;
const hostnameSplited = hostname.split('.');
let subDomain = false;
let domain = false;
let topLevelDomain = false;

if (hostname.includes('localhost')) {
    domain = 'localhost';
}

if (hostname.includes('127.0.0.1')) {
    domain = '127.0.0.1';
}

if (!domain) {
    if (hostnameSplited.length === 2) {
        domain = hostnameSplited[0];
        topLevelDomain = hostnameSplited[1];
    }

    if (hostnameSplited.length === 3) {
        subDomain = hostnameSplited[0];
        domain = hostnameSplited[1];
        topLevelDomain = hostnameSplited[2];
    }
}

$.ajaxSetup({
    method: 'POST',
    cache: false,
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    error: function (jqXHR, textStatus) {
        console.log('Ajax', 'Error', textStatus);
    }
});

$(".carousel.lazy").on('slide.bs.carousel', function (ev) {
    let lazy;
    lazy = $(ev.relatedTarget).find("img[data-src]");
    lazy.attr("src", lazy.data('src'));
    lazy.removeAttr("data-src");
});

$('[data-google-translate]').on('click', function () {
    let lang = $(this).data('google-translate');
    Cookies.set('googtrans', '/sk/' + lang, {
        path: '/',
        sameSite: 'strict'
    });

    if (subDomain) {
        Cookies.set('googtrans', '/sk/' + lang, {
            domain: '.' + domain + '.' + topLevelDomain,
            path: '/',
            sameSite: 'strict'
        });
    }

    $.ajax({
        url: '/set-lang',
        method: 'POST',
        data: {
          lang: lang
        },
        success: function (response) {
            location.reload();
        }
    });

});

const inquiryModal = $('#inquiryModal');
const inquiryForm = $('#inquiryForm');
const inquiryFormSubmitButton = $('#inquiryFormSubmitButton');

inquiryFormSubmitButton.on('click tap', function () {
    inquiryForm.submit();
});

if (inquiryModal.hasClass('inquiry-modal-show')) {
    inquiryModal.modal('show');
}

$('[required]').each(function () {
    let inputId = $(this).attr('id');
    let label = $('label[for="' + inputId + '"]');
    label.append('<sup class="required-star">*</sup>');
});

const navigationHamburger = $('#navigationHamburger');
const navigationMenu = $('#navigationMenu');

navigationHamburger.on('click tap', function () {
    if (navigationMenu.css('display') === 'flex') {
        navigationMenu.css('display', 'none');
    } else {
        navigationMenu.css('display', 'flex');
    }
});

const productCategoriesToggleButton = $('#productCategoriesToggleButton');
const productCategoriesMenu = $('#productCategoriesMenu');
const productSubCategoriesMenu = $('#productSubCategoriesMenu');

productCategoriesToggleButton.on('click tap', function () {
    if (productCategoriesMenu.css('display') === 'block') {
        productCategoriesMenu.css('display', 'none');
    } else {
        productCategoriesMenu.css('display', 'block');
    }

    if (productSubCategoriesMenu.css('display') === 'block') {
        productSubCategoriesMenu.css('display', 'none');
    } else {
        productSubCategoriesMenu.css('display', 'block');
    }
});

const customerMenuToggleButton = $('#customerMenuToggleButton');
const customerMenuMenu = $('#customerMenuMenu');

customerMenuToggleButton.on('click tap', function () {
    if (customerMenuMenu.css('display') === 'block') {
        customerMenuMenu.css('display', 'none');
    } else {
        customerMenuMenu.css('display', 'block');
    }
});

const navigationCartTotalQuantity = $('#navigationCartTotalQuantity');

const cartGetTotalQuantity = function () {
    $.ajax({
        url: '/cart/total-quantity',
        method: 'GET',
        success: function (response) {
            navigationCartTotalQuantity.text(response);
        }
    });
};

const cartSetItemQuantity = function (id, quantity) {
    if (!id || !quantity) {
        return false;
    }

    $.ajax({
        url: '/cart/quantity',
        data: {
            id: id,
            quantity: quantity
        },
        success: function () {
            cartGetTotalQuantity();
        }
    });
};

cartGetTotalQuantity();

const cartAddItem = function (product_id, quantity = 1, variant_id = null) {
    if (!product_id) {
        return false;
    }

    $.ajax({
        url: '/cart/add',
        data: {
            product_id: product_id,
            quantity: quantity,
            variant_id: variant_id
        },
        success: function () {
            window.open('/cart', '_self');
        }
    });
};

const appCart = $('#appCart');
const appCartItems = appCart.find('.items');

appCartItems.find('.item input[name="quantity"]').on('change', function () {
    let itemQuantityInput = $(this);
    let itemQuantity = parseFloat(itemQuantityInput.val());
    let item = itemQuantityInput.parents('.item');
    let itemId = item.data('id');
    let itemPrice = parseFloat(item.data('price'));
    let itemOldPrice = parseFloat(item.data('old-price'));
    let itemQuantityInterval = parseFloat(item.data('quantity-interval'));
    let itemTotalPrice = itemPrice * itemQuantityInterval * (itemQuantity / itemQuantityInterval);
    let itemTotalOldPrice = itemOldPrice * itemQuantityInterval * (itemQuantity / itemQuantityInterval);

    item.find('.pricing .price:not(.old-price)').text(price(itemTotalPrice));
    item.find('.pricing .old-price').text(price(itemTotalOldPrice));

    calculateCartTotalPrice();

    if (itemId && itemQuantity > 0) {
        cartSetItemQuantity(itemId, itemQuantity);
    } else {
        console.log('Chýba ID alebo množstvo!');
    }
});

const appProduct = $('#appProduct');
const appProductGallery = appProduct.find('.gallery');
const appProductGalleryImage = appProductGallery.find('.image');
const appProductGalleryItems = appProductGallery.find('.items');
const appProductGalleryFirstItem = appProductGallery.find('.item:first-child()');

appProductGalleryImage.zoom({
    url: appProductGalleryImage.find('img').data('image'),
    touch: false
});

const productPricing = appProduct.find('main .cart .pricing');
const productCartButton = appProduct.find('main .cart .cart-add-button');

const productPrice = $('#productPrice');
const productOldPrice = $('#productOldPrice');
const productQuantity = $('#productQuantity');
const productPriceFrom = productPricing.find('.price-from');
const productPriceDefault = parseFloat(productPrice.data('default'));
const productOldPriceDefault = parseFloat(productOldPrice.data('default'));
const productQuantityDefault = productQuantity.data('default');
const productVariantSelect = $('#productVariantSelect');

productVariantSelect.on('change', function () {
    let variantId = parseInt($(this).val());
    changeProductVariant(variantId);
});

appProductGalleryItems.find('.item').on('click tap', function () {
    let item = $(this);
    let variantId = item.data('variant');

    if (variantId) {
        changeProductVariant(variantId);
    } else {
        changeProductImage(item);
        productVariantSelect.val('');
    }

    if (item.index() === 0) {
        productVariantSelect.val('');
    }
});

const changeProductVariant = function (variantId) {
    let productVariantSelectOption = productVariantSelect.find('option[value="' + variantId + '"]');
    let productName = $('#appProduct main .info .name h1');

    if (productVariantSelectOption.length) {
        let productVariantName = productVariantSelectOption.text();
        let productVariantPrice = parseFloat(productVariantSelectOption.data('price'));
        let productVariantOldPrice = parseFloat(productVariantSelectOption.data('old-price'));
        let productVariantQuantity = productVariantSelectOption.data('quantity');

        productName.find('.variant').remove();
        productName.append('<span class="variant"> - ' + productVariantName + '</span>');

        productCartButton.prop('disabled', false);
        productPriceFrom.hide();

        if (productVariantPrice) {
            productPrice.text(price(productVariantPrice));
        } else {
            productPrice.text('');
        }

        if (productVariantOldPrice && productVariantOldPrice !== productVariantPrice) {
            productOldPrice.text(price(productVariantOldPrice));
        } else {
            productOldPrice.text('');
        }

        if (productVariantQuantity) {
            productQuantity.text(productVariantQuantity);
        } else {
            productQuantity.text('');
        }

        let image = appProductGalleryItems.find('.item[data-variant="' + variantId + '"]');

        if (image.length) {
            changeProductImage(image);
        } else {
            changeProductImage(appProductGalleryFirstItem);
        }
    } else {
        productPrice.text(productPriceDefault);

        if (productOldPriceDefault > productPriceDefault) {
            productOldPrice.text(productOldPriceDefault);
        } else {
            productOldPrice.text('');
        }

        productQuantity.text(productQuantityDefault);

        productCartButton.prop('disabled', true);
        productPriceFrom.show();

        productName.find('.variant').remove();

        changeProductImage(appProductGalleryFirstItem);
    }
    showVariantDocuments(variantId);
};

const showVariantDocuments = function (variantId) {
    $('.documents ul li').each(function (k,i) {
        let item = $(i);
        if(item.data('variant')) {
            if(variantId === item.data('variant')) {
                item.removeClass('d-none')
            } else {
                if(!item.hasClass('d-none')) {
                    item.addClass('d-none')
                }
            }
        }
    })
}

const changeProductImage = function (image) {
    let imageSrc = image.find('img').data('image-550x550');
    let imageOriginalSrc = image.find('img').data('image');
    let imageVariantId = parseInt(image.data('variant'));
    let variantSelectValue = parseInt(productVariantSelect.val());

    if (imageVariantId && productVariantSelect && imageVariantId != variantSelectValue) {
        productVariantSelect.val(imageVariantId);
    }

    appProductGalleryItems.find('.item').removeClass('active');
    image.addClass('active');

    appProductGalleryImage.trigger('zoom.destroy');

    appProductGalleryImage.find('img').attr('src', imageSrc).attr('data-src', imageSrc);
    appProductGalleryImage.zoom({
        url: imageOriginalSrc,
        touch: false
    });
};

if (productVariantSelect.length) {
    productCartButton.prop('disabled', true);
    productVariantSelect.val('');
}

productCartButton.on('click tap', function () {
    const productId = parseInt(appProduct.find('input[name="product_id"]').val());
    let variantId = parseInt(productVariantSelect.val()) || null;

    let quantity = parseFloat(productQuantity.text().replace(',', '.')) || 1;

    if (productId) {
        cartAddItem(productId, quantity, variantId);
    } else {
        console.log('Chýba ID!');
    }
});

const cartTotalPrice = $('#cartTotalPrice');
const cartTotalOldPrice = $('#cartTotalOldPrice');
const cartTotalDiscount = $('#cartTotalDiscount');

const calculateCartTotalPrice = function () {
    let totalPrice = 0;
    let totalOldPrice = 0;

    appCartItems.find('.item').each(function () {
        let item = $(this);
        let quantity = parseFloat(item.find('input[name="quantity"]').val());

        let price = parseFloat(item.data('price'));
        let oldPrice = parseFloat(item.data('old-price'));

        if (price) {
            totalPrice += (price * quantity);
        }

        if (oldPrice) {
            totalOldPrice += (oldPrice * quantity);
        }
    });

    let totalDiscount = totalOldPrice - totalPrice;

    cartTotalPrice.text(price(totalPrice));
    cartTotalOldPrice.text(price(totalOldPrice));
    cartTotalDiscount.text(price(totalDiscount));
};

const cartShippingAddressCheckbox = $('#cartShippingAddressCheckbox');
const cartShippingAddressDiv = $('#cartShippingAddress');

const cartShippingAddress = function () {
    let checked = cartShippingAddressCheckbox.prop('checked');

    if (checked) {
        cartShippingAddressDiv.removeClass('disabled');

    } else {
        cartShippingAddressDiv.addClass('disabled');
    }
};

cartShippingAddress();

cartShippingAddressCheckbox.on('change', function () {
    cartShippingAddress();
});

function price (n, c = '.', d = ',', t = ' ') {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? '.' : d,
        t = t == undefined ? ',' : t,
        s = n < 0 ? '-' : '',
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    var x = c = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    return x.replace(',00', '').replace('.00', '');
}

const appCategory = $('#appCategory');
const appCategoryText = appCategory.find('main .category .text');

if (appCategoryText.find('> *').length > 1) {
    appCategoryText.find('> *').hide();
    appCategoryText.find('> *:first-child()').show();

    let showMoreTextButton = $('<span></span>', {
        'class': 'show-more-text',
        'text': 'viac'
    });

    let showLessTextButton = $('<span></span>', {
        'class': 'show-less-text',
        'text': 'menej'
    });

    appCategoryText.append(showMoreTextButton);
    appCategoryText.append(showLessTextButton);

    showLessTextButton.hide();

    showMoreTextButton.on('click tap', function () {
        appCategoryText.find('> *').show();

        showMoreTextButton.hide();
        showLessTextButton.show();
    });

    showLessTextButton.on('click tap', function () {
        appCategoryText.find('> *').hide();
        appCategoryText.find('> *:first-child()').show();

        showMoreTextButton.show();
        showLessTextButton.hide();
    });
}

$('.num').each(function () {
    $(this).prop('Counter',0).animate({
        Counter: $(this).text()
    }, {
        duration: 1980,
        easing: 'swing',
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    });
});


const referenceItem = $('.reference-item');

referenceItem.on('click tap', function () {
    const referenceModal = $('#reference-modal');
    let referenceId = $(this).data('id')

    $.ajax({
        url: '/referencie/ajax-detail',
        method: 'GET',
        data: {
            id: referenceId
        },
        success: function (response) {
            referenceModal.find('.modal-title').html(response.name);
            referenceModal.find('.zdroj').html(response.source);
            referenceModal.find('.mod-text').html(response.description);
            let materialsTitle = referenceModal.find('.materials-title')
            if(response.products.length) {
                materialsTitle.removeClass('d-none');
                let productsHtml = ''
                $.each(response.products, function (k,i) {
                    productsHtml += `<a href="/${i.slug}" target="_blank">${i.name}</a>`
                    if(k < response.products.length) {
                        productsHtml += ', '
                    }
                })
                referenceModal.find('.mod-tit').html(productsHtml)
            } else {
                if(!materialsTitle.hasClass('d-none')) {
                    materialsTitle.addClass('d-none')
                }
            }
            let area = referenceModal.find('.area')
            let year = referenceModal.find('.year')
            if(response.area) {
                area.removeClass('d-none');
                referenceModal.find('.area-value').html(response.area)
            } else {
                if(!area.hasClass('d-none')) {
                    area.addClass('d-none')
                }
            }
            if(response.year) {
                year.removeClass('d-none');
                referenceModal.find('.year-value').html(response.year)
            } else {
                if(!year.hasClass('d-none')) {
                    year.addClass('d-none')
                }
            }
            let referenceModalImage = referenceModal.find('.reference-modal-image')
            if(response.thumbImage) {
                referenceModalImage.removeClass('d-none')
                referenceModalImage.attr('src', response.thumbImage)
            } else {
                if(!referenceModalImage.hasClass('d-none')) {
                    referenceModalImage.addClass('d-none')
                }
            }
        }
    });
    referenceModal.modal();
})

const popupModal = $('#popup-modal');

if (popupModal.length) {
    popupModal.modal();
}

const moreArticles = $('#more-articles')
moreArticles.on('click tap', function (){
    $.ajax({
        url: '/aktuality/viac',
        method: 'GET',
        data: {
            last: moreArticles.data('last')
        },
        success: function (response) {
            let lastItem = $('.news-item:last')
            lastItem.after(response.items);
            if(response.allArticlesCount === response.last) {
                $('#more-articles-section').addClass('d-none')
            } else {
                moreArticles.data('last', response.last)
            }
        }
    });
})

jQuery.ui.autocomplete.prototype._resizeMenu = function () {
    var ul = this.menu.element;
    ul.outerWidth(this.element.outerWidth());
}

let searchType = false
$("#search").autocomplete({
    focus: function( event, ui ) {
        searchType = false
        return false;
    },
    source: '/search',
}).data( "ui-autocomplete" )._renderItem = function( ul, item ) {
    let inner_html = ''
    if(!searchType || item.type !== searchType) {
        searchType = item.type
        inner_html += `<div class="type-line"><span>${item.type}</span></div>`
    }
    inner_html += `<a href="${item.url}">
                    <div class="list_item_container d-flex align-items-center">
                        <div class="image mr-2">
                            <img src="${item.image}" width="70" height="70">
                        </div>
                        <div class="label">
                            <h6 class="h6 mb-0">${item.title}</h5>
                        </div>
                    </div>
                  </a>`;
    return $("<li></li>")
        .data("item.autocomplete", item)
        .append(inner_html)
        .appendTo(ul);
}

const videoCarousel = $('.video-wrapp-carousel video')
const playBtnCarousel = $('.video-wrapp-carousel .playBtn')
const videoBtnCarousel = $('.video-wrapp-carousel .video-btn')
const videoCloseCarousel = $('.video-wrapp-carousel .video-close')
const blackVideoBgCarousel = $('.video-wrapp-carousel .black-video-bg')
if(videoCarousel.length) {
    if($(window).width() < 768) {
        playBtnCarousel.attr('href', videoCarousel[0].currentSrc)
    } else {
        videoCarousel.attr('autoplay');
        if(videoCarousel.length) {
            videoCarousel[0].play();
        }
        playBtnCarousel.removeAttr('href')
        playBtnCarousel.on('click', function () {
            blackVideoBgCarousel.removeClass('d-none')
            videoCloseCarousel.removeClass('d-none')
            videoCarousel.addClass('video-play')
            videoCarousel.prop('muted', false)
        })
    }
    videoBtnCarousel.on('click', function() {
        if($(window).width() < 768) {
            videoBtnCarousel.attr('href', videoCarousel[0].currentSrc)
        } else {
            blackVideoBgCarousel.removeClass('d-none')
            videoCarousel.removeClass('d-none')
            videoCarousel.addClass('video-play')
            videoCloseCarousel.removeClass('d-none')
            videoCarousel[0].play()
            videoCarousel.get(0).currentTime = 0
        }
    })
    videoCloseCarousel.on('click', function () {
        blackVideoBgCarousel.addClass('d-none')
        videoCarousel.removeClass('video-play')
        videoCloseCarousel.addClass('d-none')
        videoCarousel.prop('muted', true);
    })
}

const videoBottom = $('.video-wrapp video')
const playBtnBottom = $('.video-wrapp .playBtn')
const videoBtnBottom = $('.video-wrapp .video-btn')
const videoCloseBottom = $('.video-wrapp .video-close')
const blackVideoBgBottom = $('.video-wrapp .black-video-bg')
if(videoBottom.length) {
    if($(window).width() < 768) {
        playBtnBottom.attr('href', videoBottom[0].currentSrc)
        let h = $('.carousel-item.active').height()
        $('.carousel-item').eq(1).height(h)
    } else {
        videoBottom.attr('autoplay');
        if(videoBottom.length) {
            videoBottom[0].play();
        }
        playBtnBottom.removeAttr('href')
        playBtnBottom.on('click', function () {
            blackVideoBgBottom.removeClass('d-none')
            videoCloseBottom.removeClass('d-none')
            videoBottom.addClass('video-play')
            videoBottom.prop('muted', false)
        })
    }
    videoBtnBottom.on('click', function() {
        if($(window).width() < 768) {
            videoBtnBottom.attr('href', videoBottom[0].currentSrc)
        } else {
            blackVideoBgBottom.removeClass('d-none')
            videoBottom.removeClass('d-none')
            videoBottom.addClass('video-play')
            videoCloseBottom.removeClass('d-none')
            videoBottom[0].play()
            videoBottom.get(0).currentTime = 0
        }
    })
    videoCloseBottom.on('click', function () {
        blackVideoBgBottom.addClass('d-none')
        videoBottom.removeClass('video-play')
        videoCloseBottom.addClass('d-none')
        videoBottom.prop('muted', true);
    })
}

const appDownloadMore = $('.menu-item-more')

appDownloadMore.on('click tap', function () {
    let next = $(this).next()
    let downloadSubSubmenu = $('.download-sub-submenu')
    if(downloadSubSubmenu.length) {
        downloadSubSubmenu.slideUp(200)
    }
    next.slideToggle(200)
})

const counterHolder = $('#counter-holder')

if(counterHolder.length) {
    if($(window).width() < 768) {
        counterHolder.insertBefore("#newsletter-holder");
        counterHolder.css('display', 'flex')
    }
}
$('#collapser').on('click tap', function() {
    $('#collapseExample').collapse('toggle')
})

$('#newsletter-form .newsletter-btn').on('click touch', function (e) {
    e.preventDefault();
    let siteKey = $(this).data('key')
    grecaptcha.ready(function() {
        grecaptcha.execute(siteKey, {action: 'submit'}).then(function(token) {
            $.ajax({
                url: '/verify-captcha',
                method: 'POST',
                data: {
                    token: token
                },
                success: function (response) {
                    if(response.status === 'success' && response.response.success) {
                        $('#newsletter-form').submit()
                    }
                    console.log('Skóre pod hodnotou 0.3, formulár neodoslaný')
                }
            });
        });
    });
})

const carouselExampleIndicators = $('#carouselExampleIndicators')
carouselExampleIndicators.on('slid.bs.carousel',function(e){
    $('.carousel-caption').removeClass('d-none')
})
carouselExampleIndicators.on('slide.bs.carousel',function(e){
    if(e.to !== 0) {
        $('h1').fadeOut()
    } else {
        $('h1').fadeIn()
    }
    $('.carousel-caption').addClass('d-none')
});
